var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"home page-template-default page page-id-12105 wp-custom-logo header-horizontal site-layout-full elementor-default elementor-kit-12104 elementor-page elementor-page-12105"},[_c('div',{staticClass:"whole-layout"},[_c('div',{staticClass:"page-background to-move"}),_c('footer',{staticClass:"to-move full centered footer-separator",attrs:{"id":"footer","itemtype":"https://schema.org/WPFooter","itemscope":""}},[_c('div',{staticClass:"foot-widgets five-col dark-sidebar"},[_c('div',{staticClass:"foot-content clearfix"},[_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_c('div',{staticClass:"widget widget_text",attrs:{"id":"text-18"}},[_vm._m(4),_c('div',{staticClass:"footer-item-box",on:{"click":function($event){return _vm.skip('https://www.aromore.com.cn/')}}},[_c('img',{staticClass:"footer-item-p1",attrs:{"alt":"小茉护肤","src":require("@/assets/images/pic-art.png")}})]),_c('div',{staticClass:"footer-item-box",on:{"click":function($event){return _vm.skip('https://www.douhuu.com.cn/')}}},[_c('img',{staticClass:"footer-item-p2",attrs:{"alt":"豆乎","src":require("@/assets/images/partner-2.png")}})])])])]),_vm._m(5)]),_c('a',{staticClass:"to-top fa fa-angle-up",attrs:{"id":"to-top"}}),_c('div',{staticClass:"to-move",attrs:{"id":"content-overlay"}})])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"widget_text widget widget_custom_html",attrs:{"id":"custom_html-3"}},[_c('h3',{staticClass:"title"},[_c('span',[_vm._v("关注我们/FOLLOW")])]),_c('div',{staticClass:"textwidget custom-html-widget"},[_c('div',{staticClass:"content-text"},[_c('br'),_vm._v(" 官方微信公众号 ")]),_c('br'),_c('a',{attrs:{"id":"follow-weixxinvideo"}},[_vm._v("官方微信视频号")]),_c('br'),_c('a',{attrs:{"id":"follow-weibo"}},[_vm._v("官方微博")]),_c('br')])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"widget widget_contact_info",attrs:{"id":"contact-info-3"}},[_c('h3',{staticClass:"title"},[_c('span',[_vm._v("联系我们/CONTACT")])]),_c('div',{staticClass:"info"},[_c('div',{staticClass:"content-text"},[_vm._v("广州总部：020-29121923"),_c('br'),_vm._v(" 广州市白云区齐心路68号中国南方航空大厦4楼"),_c('br')])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"widget widget_contact_info",attrs:{"id":"contact-info-4"}},[_c('h3',{staticClass:"title"},[_c('span',[_vm._v("咨询了解/CONSULTATIVE")])]),_c('div',{staticClass:"info"},[_c('div',{staticClass:"content-text"},[_vm._v("渠道加盟"),_c('br'),_vm._v(" Email：contact@aromore.com"),_c('br'),_c('br'),_vm._v(" 业务咨询 "),_c('br'),_vm._v(" Email：service@aromore.com"),_c('br'),_c('br')])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"widget widget_text",attrs:{"id":"text-18"}},[_c('h3',{staticClass:"title"},[_c('span',[_vm._v("帮助中心/help")])]),_c('div',{staticClass:"textwidget"},[_c('p',[_c('a',{attrs:{"title":"《用户注册与服务协议》《隐私政策》变更公告-20220312","target":"_blank","rel":"noopener"}},[_vm._v("最新公告")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('h3',{staticClass:"title"},[_c('span',[_vm._v("家族网站/enter")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"foot-items"},[_c('div',{staticClass:"foot-content clearfix"},[_c('div',{staticClass:"foot-text"},[_c('p',{staticStyle:{"text-align":"center"}},[_vm._v("© 2023-2024 阿茉尔（广州）生物科技有限公司    "),_c('a',{attrs:{"target":"_blank","href":"https://beian.miit.gov.cn/"}},[_vm._v("粤ICP备2024168453号-4")])]),_c('br')])])])
}]

export { render, staticRenderFns }
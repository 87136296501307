import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import AreaView from "@/views/AreaView.vue";
import AreaReactView from "@/views/AreaReactView.vue";
import AreaBioView from "@/views/AreaBioView.vue";
import AreaTreeView from "@/views/AreaTreeView.vue";
import AreaPackView from "@/views/AreaPackView.vue";
import AreaFlavorView from "@/views/AreaFlavorView.vue";
import AreaCompView from "@/views/AreaCompView.vue";
import AromoreView from "@/views/AromoreView.vue";
import BrandView from "@/views/BrandView.vue";

Vue.use(VueRouter)

// 防止跳转同一路径出异常
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch(err => err)
}

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/applet',
    name: 'applet',
    component: AromoreView
  },
  {
    path: '/brand',
    name: 'brand',
    component: BrandView
  },
  {
    path: '/field',
    name: 'field',
    component: AreaView
  },
  {
    path: '/field/react',
    name: 'fieldReact',
    component: AreaReactView
  },
  {
    path: '/field/bio',
    name: 'fieldBio',
    component: AreaBioView
  },
  {
    path: '/field/tree',
    name: 'fieldTree',
    component: AreaTreeView
  },
  {
    path: '/field/pack',
    name: 'fieldPack',
    component: AreaPackView
  },
  {
    path: '/field/flavor',
    name: 'fieldFlavor',
    component: AreaFlavorView
  },
  {
    path: '/field/comp',
    name: 'fieldComp',
    component: AreaCompView
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
